
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, NgZone } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { take } from "rxjs/operators";
import { CommonUtils } from "src/app/common/utils/common-util";
import { environment } from "src/environments/environment";
import { ElectronService } from "src/app/services/electron.service";
import { ConfigService } from "src/app/config.service";

@Component({
  selector: "vp-about-dialog",
  templateUrl: "./about-dialog.component.html"
})
export class AboutDialogComponent {
  app_version: string = "3.0.5.7648";
  aboutLogo: string;
  aboutText: string;
  changeLog: string;
  backgroundImage = "url('assets/img/login-background.png')";
  prefixBold: string;
  suffixNormal: string;
  calendarOnly = environment.calendarOnly;
  constructor(
    public dialogRef: MatDialogRef<AboutDialogComponent>,
    private mailBroadcaster: MailBroadcaster,
    private ngZone: NgZone,
    private electronService: ElectronService,
    private configService: ConfigService
  ) {
    this.prefixBold = this.configService.get("prefixBold");
    this.suffixNormal = this.configService.get("suffixNormal");
    if (this.calendarOnly) {
      this.prefixBold = "VNC";
      this.suffixNormal = "calendar";
    }
    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_VERSION_DIALOG).pipe(take(1)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });
    if (environment.isCordova) {
      document.addEventListener("deviceready", this.deviceReady.bind(this), false);
    }
    // if (this.electronService.isElectron) {
    //   this.app_version = this.electronService.appVersion;
    // }
    this.aboutLogo = environment.aboutUsLogo;
    this.aboutText = environment.aboutText;
    if (this.configService.URLS.changeLog) {
      this.changeLog = this.configService.URLS.changeLog;
    } else {
      this.changeLog = "https://vnclagoon.com/release-notes/?note-group=vncmail";
    }
  }

  deviceReady() {
    try {
      cordova.getAppVersion.getVersionNumber().then(versionNumber => {
        console.log("AboutDialogComponent versionNumber", versionNumber);
        if (CommonUtils.isOnAndroid()) {
          // for Android we use ${VERSION}.${env.BUILD_NUMBER} as a version
          // so do not need to pass a build number since version contains both.
          this.app_version = versionNumber;
          console.log("AboutDialogComponent Android this.app_version", this.app_version);
        } else {
          cordova.getAppVersion.getVersionCode().then(buildNumber => {
            console.log("AboutDialogComponent buildNumber", buildNumber);
            this.app_version = versionNumber + "." + buildNumber;
            console.log("AboutDialogComponent iOS this.app_version", this.app_version);
          });
        }
      });
    } catch (e) {
      console.log("[getVersionNumber] err", e);
    }
  }


  close() {
    this.dialogRef.close();
  }
}
